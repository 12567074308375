<div class="flex flex-col flex-auto">
  <!-- Header -->
  <div
    class="flex flex-col items-start p-6 border-b sm:flex-row sm:items-center sm:justify-between sm:py-12 md:px-8 bg-card dark:bg-transparent concierge-border-color"
  >
    <!-- Title -->
    <div>
      <div class="text-4xl font-extrabold leading-none tracking-tight">
        <ng-content select="[title]"></ng-content>
      </div>
      <div class="flex items-center mt-0.5 font-medium text-secondary"></div>
    </div>
    <!-- Actions -->
    <div class="mt-4 sm:mt-0">
      <ng-content select="[actions]"></ng-content>
    </div>
  </div>

  <!-- Body -->
  <ng-container>
    <div class="p-6 space-y-8 md:p-8">
      <ng-content></ng-content>
    </div>
  </ng-container>
</div>
