/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [];
export const compactNavigation: FuseNavigationItem[] = [
  // {
  //   id: 'dashboard',
  //   title: 'Dashboard',
  //   tooltip: 'Dashboard',
  //   type: 'basic',
  //   icon: 'heroicons_solid:home',
  //   link: '/dashboard',
  // },
  // {
  //   id: 'videos',
  //   title: 'Videos',
  //   tooltip: 'Videos',
  //   type: 'basic',
  //   icon: 'mat_outline:monitor',
  //   link: '/videos',
  // },
  // {
  //   id: 'detections',
  //   title: 'Detections',
  //   tooltip: 'Detections',
  //   type: 'basic',
  //   icon: 'heroicons_outline:exclamation',
  //   link: '/detection',
  // },
  // {
  //   id: 'robots',
  //   title: 'Robots',
  //   tooltip: 'Robots',
  //   type: 'basic',
  //   icon: 'mat_outline:smart_toy',
  //   link: '/robots',
  // },
  // {
  //   id: 'malfunctions',
  //   title: 'Malfunctions',
  //   tooltip: 'Malfunctions',
  //   type: 'basic',
  //   icon: 'mat_outline:build_flip',
  //   link: '/malfunctions',
  // },
  // {
  //   id: 'dispatch-robots',
  //   title: 'Dispatch Robots',
  //   tooltip: 'Dispatch Robots',
  //   type: 'basic',
  //   icon: 'mat_outline:dispatch_robot',
  //   link: '/dispatch-robots',
  // },
  // {
  //   id: 'notes',
  //   title: 'Notes',
  //   tooltip: 'Call Logs',
  //   type: 'basic',
  //   icon: 'mat_outline:call',
  //   link: '/record-notes',
  // },
  // {






  {
    id: 'liveview',
    title: 'Live View',
    tooltip: 'Live View',
    type: 'basic',
    icon: 'mat_outline:live_view',
    link: '/live-view',
  },
  {
    id: 'alarm',
    title: 'Alarm',
    tooltip: 'Alarm',
    type: 'basic',
    icon: 'mat_outline:nav_alarm',
    link: '/alarm',
  },
  {
    id: 'report',
    title: 'Report',
    tooltip: 'Report',
    type: 'basic',
    icon: 'mat_outline:nav_report',
    link: '/report',
  }
];
export const futuristicNavigation: FuseNavigationItem[] = [];
export const horizontalNavigation: FuseNavigationItem[] = [];
