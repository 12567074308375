import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { ApiEventDetection } from 'app/services/event.service';
import { MalfunctionService } from 'app/services/malfunction.service';
import { SnackBarService } from 'app/services/snack-bar.service';
import { isForbidden } from 'app/shared/directives/validator.directive';
import { IResponse } from 'rm-api-services/dist/api-services/lib/robot_maps.model';
import { Subject, Subscription, takeUntil } from 'rxjs';

@Component({
  selector: 'app-rm-remarks',
  templateUrl: './rm-remarks.component.html',
  styleUrls: ['./rm-remarks.component.scss'],
})
export class RmRemarksComponent implements OnInit, OnDestroy {
  @Input() isCaseClosed: boolean;
  @Input() moduleId: string;
  @Input() modules: string;
  @Input() refreshRemarks: boolean = false;
  @ViewChild('remarks') remarksElement: ElementRef;

  moduleName: string;
  remarkForm: FormGroup;
  listRemarks;
  totalRemarks: number = 0;
  pageNumber: number = 1;
  pageSize: number = 5;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  isInvalid: boolean = false;

  private subsIsAfterActionDetection: Subscription;
  private unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private _eventService: ApiEventDetection,
    private _malfunctionService: MalfunctionService,
    private snackbar: SnackBarService
  ) {}

  ngOnInit(): void {
    if (!this.isCaseClosed) {
      this.remarkForm = new FormGroup({
        comment: new FormControl('', Validators.required),
      });
    }

    this.fetchRemarks();
    if (this.moduleName === 'detection') {
      //fetch new remark if the user update the detection from unresolve to resolve or vise versa
      this.subsIsAfterActionDetection =
        this._eventService.isAfterActionDetectionSubject
          .pipe(takeUntil(this.unsubscribeAll))
          .subscribe((data) => {
            if (data) {
              this.fetchRemarks();
            }
          });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next(null);
    this.unsubscribeAll.complete();
    this.subsIsAfterActionDetection?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.modules) {
      this.moduleName = changes.modules.currentValue;
    }
    // fetch new remark if the user update the modules from detection or malfunction
    if (changes.refreshRemarks.currentValue) {
      this.fetchRemarks();
    }
  }

  /**
   * Fetches remarks based on the selected module.
   */
  private fetchRemarks(): void {
    let payload;
    switch (this.modules) {
      case 'malfunction':
        // Security malfunction pagination payload
        payload = {
          pageNo: this.pageNumber,
          pageSize: this.pageSize,
          orders: [
            {
              column: 'created_at',
              order: 'desc',
            },
          ],
          filters: [
            {
              column: 'malfunction_id',
              name: 'MalfunctionId',
              operator: '=',
              values: [this.moduleId],
            },
          ],
        };
        this._malfunctionService
          .getNotes(payload)
          .pipe(takeUntil(this.unsubscribeAll))
          .subscribe((response) => {
            if (response.code !== 200) {
              this.snackbar.openSnackBar({
                message: response.message,
                type: 'failed',
              });
            }
            this.listRemarks = response.result.list;
            this.totalRemarks = response.result.totalRecords;
          });
        break;
      case 'detection':
        // RM Core detection payload
        payload = {
          pageNo: this.pageNumber,
          pageSize: this.pageSize,
          order: [
            {
              name: 'CreatedTime',
              column: 'created_at',
              type: 'desc',
            },
          ],
          filter: [
            {
              column: 'event_id',
              name: 'EventId',
              operator: 'eq',
              value: [this.moduleId],
            },
          ],
        };

        this._eventService
          .getComments(payload)
          .pipe(takeUntil(this.unsubscribeAll))
          .subscribe((commentRes: IResponse) => {
            if (commentRes.code !== 200) {
              this.snackbar.openSnackBar({
                message: commentRes.message,
                type: 'failed',
              });
            }
            this.listRemarks = commentRes.result.list;
            this.totalRemarks = commentRes.result.totalRecords;
          });
        break;
    }
  }

  pageChanged(event: PageEvent): void {
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageIndex + 1;
    this.fetchRemarks();
  }

  handleRemarkInput(val: string) {
    this.isInvalid = !isForbidden(val);
  }

  /**
   * Adds a remark based on the comment value.
   * If the module is detection, it will call the postComment API for event.
   * If the module is malfunction, it will call the postNotes API for malfunction.
   *
   */
  addRemark(): void {
    const commentValue = this.remarkForm.get('comment').value;
    if (commentValue) {
      if (this.moduleName === 'detection') {
        const dataComment = {
          content: commentValue,
          eventId: this.moduleId,
        };

        this._eventService
          .postComment(dataComment)
          .pipe(takeUntil(this.unsubscribeAll))
          .subscribe((response) => {
            if (response.message === 'Success') {
              this.snackbar.openSnackBar({
                message: 'A new remark was added successfully.',
                type: 'success',
              });
              this.remarkForm.reset();
              this.fetchRemarks();
              this.scrollToRemarks();
            } else {
              this.snackbar.openSnackBar({
                message: 'Added new remark failed.',
                type: 'failed',
              });
            }
          });
      } else {
        const dataComment = {
          note: commentValue,
          malfunctionId: this.moduleId,
        };

        this._malfunctionService
          .postNotes(dataComment)
          .pipe(takeUntil(this.unsubscribeAll))
          .subscribe((response) => {
            if (response.code === 200) {
              this.snackbar.openSnackBar({
                message: 'A new remark was added successfully.',
                type: 'success',
              });
              this.remarkForm.reset();
              this.fetchRemarks();
              this.scrollToRemarks();
            } else {
              this.snackbar.openSnackBar({
                message: 'Added new remark failed.',
                type: 'failed',
              });
            }
          });
      }
    }
  }

  private scrollToRemarks(): void {
    if (this.remarksElement?.nativeElement) {
      this.remarksElement.nativeElement.scrollIntoView({
        behavior: 'smooth',
      });
    } else {
      setTimeout(() => {
        this.scrollToRemarks();
      }, 100);
    }
  }
}
