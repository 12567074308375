import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'shared-loading-spinner',
  template: `<div class="loader-spinner">
    <mat-spinner></mat-spinner>
  </div>`,
  styles: [
    `
      .loader-spinner {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 100;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #083A45
      }
    `,
  ],
})
export class LoadingSpinnerComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
