import { Injectable } from '@angular/core';
import { DispatchRobotPayload } from 'app/services/api.types';
import { environment } from 'environments/environment';
import { PointExpression } from 'leaflet';
import {
  ApiLayout,
  ApiMission,
  ApiRobot,
  Job,
  Layout,
  Mission,
  ResponseList,
  ResponseOne,
  Robot,
  Skill,
} from 'rm-api-services/dist/api-services';
import { map, Observable, ReplaySubject } from 'rxjs';

export interface MonitoringRobot extends Robot {
  layout?: Layout;
  stateDetail?: string;
}

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  apiUrl: string;
  private _dashboardLayoutMarkers: ReplaySubject<ResponseList<Layout>> =
    new ReplaySubject<ResponseList<Layout>>(1);
  private _currentActiveMission: ReplaySubject<Mission[]> = new ReplaySubject<
    Mission[]
  >(1);

  public selectedRobotId$: ReplaySubject<string> = new ReplaySubject<string>(1);
  public mapZoom$: ReplaySubject<number> = new ReplaySubject<number>(1);
  public selectedSkill$: ReplaySubject<Skill> = new ReplaySubject<Skill>(1);
  public listSkill$: ReplaySubject<Skill[]> = new ReplaySubject<Skill[]>(1);
  public dispatchRobotPayload$: ReplaySubject<DispatchRobotPayload> =
    new ReplaySubject<DispatchRobotPayload>(1);

  // used in multiple robot job creation drawer
  public selectedTypeCreateJob$: ReplaySubject<string> =
    new ReplaySubject<string>(1);
  public selectedSkillMultipleRobot$: ReplaySubject<Skill> =
    new ReplaySubject<Skill>(1);
  public selectedMultipleRobotId$: ReplaySubject<string> =
    new ReplaySubject<string>(1);
  public selectedDispatchRobotId$: ReplaySubject<string> =
    new ReplaySubject<string>(1);
  public selectedRobotCoordinate$: ReplaySubject<PointExpression> =
    new ReplaySubject<PointExpression>(1);
  public selectedRobots$: ReplaySubject<Robot[]> = new ReplaySubject<Robot[]>(
    1
  );
  public isMultipleRobotJobDrawerOpened$: ReplaySubject<boolean> =
    new ReplaySubject<boolean>(1);
  public selectedJobType$: ReplaySubject<string> = new ReplaySubject<string>(1);

  public toggleRobotDetails$: ReplaySubject<boolean> =
    new ReplaySubject<boolean>(1);
  public toggleDispatchRobotDrawer$: ReplaySubject<boolean> =
    new ReplaySubject<boolean>(1);

  public resizeGisMap$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  /**
   * Constructor
   */
  constructor(private _apiLayout: ApiLayout, private _apiMision: ApiMission) {
    this.apiUrl = environment.coreApiUrl;
    this._apiLayout.baseUrl = environment.coreApiUrl;
  }

  get dashboardLayoutMarkers$(): Observable<ResponseList<Layout>> {
    return this._dashboardLayoutMarkers.asObservable();
  }

  get currentActiveMissions$(): Observable<Mission[]> {
    return this._currentActiveMission.asObservable();
  }

  getCurrentMission(id: string): Observable<ResponseList<Mission>> {
    const psd = {
      pageNo: 1,
      pageSize: 10,
      filter: [
        {
          name: 'Status',
          column: 'missions.status',
          operator: 'eq',
          value: ['5'],
          extra: 'data_dict("MissionStatus")',
          dataType: 'enum',
          virtual: false,
        },
        {
          name: 'robotId',
          column: 'robot_missions.robot_id',
          operator: 'eq',
          value: [id],
          extra: '',
          dataType: 'text',
          virtual: false,
        },
      ],
      order: [
        {
          name: 'Update Time',
          column: 'updated_at',
          type: 'desc',
        },
      ],
    };
    return this._apiMision.post('mission/list-join-robot', psd);
  }

  public pauseAllCurrentJob(robotId: string): Promise<boolean> {
    return new Promise((resolve) => {
      this.getAllJobsWithStatuses(robotId, [`5`]).subscribe((missions) => {
        if (missions.length > 0) {
          const updateMissionPromises = missions.map((mission) => {
            const data = {
              id: mission.id,
              status: 3, // pause
              name: mission.name.includes('[MISSION]')
                ? `${mission.name} [MISSION]`
                : mission.name,
              desc: mission.description,
            };

            return this._apiMision
              .updateMission(data)
              .toPromise()
              .then((response: ResponseOne<Job>) => {
                return response.code === 200;
              });
          });

          Promise.all(updateMissionPromises).then((results) => {
            const allPaused = results.every((result) => result);
            this._currentActiveMission.next([]);
            resolve(allPaused);
          });
        } else {
          this._currentActiveMission.next([]);
          resolve(true); // No missions to pause, consider it successful
        }
      });
    });
  }

  /**
   * Aborts all current jobs for a given robot.
   *
   * @param {string} robotId - The ID of the robot.
   * @return {Promise<boolean>} A promise that resolves to true if all jobs were successfully aborted, or false otherwise.
   */
  public abortAllCurrentJob(robotId: string): Promise<boolean> {
    return new Promise((resolve) => {
      this.getAllJobsWithStatuses(robotId, [`5`]).subscribe((missions) => {
        if (missions.length > 0) {
          const updateMissionPromises = missions.map((mission) => {
            const data = {
              id: mission.id,
              status: 2, // abort
              name: mission.name.includes('[MISSION]')
                ? `${mission.name} [MISSION]`
                : mission.name,
              desc: mission.description,
            };

            return this._apiMision
              .updateMission(data)
              .toPromise()
              .then((response: ResponseOne<Job>) => {
                return response.code === 200;
              });
          });

          Promise.all(updateMissionPromises).then((results) => {
            const allAborted = results.every((result) => result);
            this._currentActiveMission.next([]);
            resolve(allAborted);
          });
        } else {
          this._currentActiveMission.next([]);
          resolve(true); // No missions to abort, consider it successful
        }
      });
    });
  }

  public getAllJobsWithStatusFilter(
    robotId: string,
    jobStatus: number
  ): Observable<Mission[]> {
    const layoutIdArr: string[] = [];
    let statusArr = undefined;
    const psd = this._apiMision.generateMissionPayload({
      layoutIdArr,
      statusArr,
      pageNo: 1,
      pageSize: 100,
    });
    psd.filter.push({
      name: 'robotId',
      column: 'robot_missions.robot_id',
      operator: 'eq',
      extra: '',
      value: ['' + robotId + ''],
      dataType: 'text',
      virtual: false,
    });
    psd.filter.push({
      name: 'status',
      column: 'status',
      operator: 'in',
      extra: '',
      value: [`${jobStatus}`],
      dataType: 'integer',
      virtual: false,
    });

    return this._apiMision
      .post('mission/list-join-robot', psd)
      .pipe(map((res) => res.result.list));
  }

  private getAllJobsWithStatuses(
    robotId: string,
    statuses: string[]
  ): Observable<Mission[]> {
    if (!robotId) return;

    const layoutIdArr: string[] = [];
    let statusArr = undefined;
    const psd = this._apiMision.generateMissionPayload({
      layoutIdArr,
      statusArr,
      pageNo: 1,
      pageSize: 100,
      order: [
        {
          name: 'Created Time',
          column: 'created_at',
          type: 'desc',
        },
      ],
    });
    psd.filter.push({
      name: 'robotId',
      column: 'robot_missions.robot_id',
      operator: 'eq',
      extra: '',
      value: ['' + robotId + ''],
      dataType: 'text',
      virtual: false,
    });
    psd.filter.push({
      name: 'status',
      column: 'status',
      operator: 'in',
      extra: '',
      value: statuses,
      dataType: 'integer',
      virtual: false,
    });

    return this._apiMision
      .post('mission/list-join-robot', psd)
      .pipe(map((res) => res.result.list));
  }

  public getAllActiveJobs(robotId?: string): Observable<Mission[]> {
    if (!robotId) return;

    return this.getAllJobsWithStatuses(robotId, [`3`, `4`, `5`]);
  }
}
