import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { map, Observable, startWith, Subject, takeUntil } from 'rxjs';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import {
  FuseNavigationService,
  FuseVerticalNavigationComponent,
} from '@fuse/components/navigation';
import { Navigation } from 'app/core/navigation/navigation.types';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { FormBuilder, FormControl } from '@angular/forms';
import { ToggleContentService } from 'app/services/toggle-content.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'thin-layout',
  styleUrls: ['./thin-component.scss'],
  templateUrl: './thin.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class ThinLayoutComponent implements OnInit, OnDestroy {
  opened: boolean;
  navigation: Navigation;
  fullPageView: boolean = false;
  showTitle: boolean = false;
  isDetectionPage: boolean;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  /**
   * Constructor
   */
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _navigationService: NavigationService,
    private _fuseMediaWatcherService: FuseMediaWatcherService,
    private _fuseNavigationService: FuseNavigationService,
    private _formBuilder: FormBuilder
  ) {
    //check the page url this 2 check needed
    if (
      window.location.href.includes('detection') ||
      window.location.href.includes('teleoperation')
    ) {
      this.isDetectionPage = window.location.href.includes('detection')
        ? true
        : false;
      this.fullPageView = true;
      // console.log('this.isDetectionPage', this.isDetectionPage);
    } else {
      this.fullPageView = false;
      // console.log('this.isDetectionPage', this.isDetectionPage);
    }

    //check the page url this 2 check needed
    this._router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        if (
          e['url'].includes('detection') ||
          e['url'].includes('teleoperation')
        ) {
          this.isDetectionPage = e['url'].includes('detection') ? true : false;
          this.fullPageView = true;
          // console.log('this.isDetectionPage', this.isDetectionPage);
        } else {
          this.fullPageView = false;
          // console.log('this.isDetectionPage', this.isDetectionPage);
        }
      }
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for current year
   */
  get currentYear(): number {
    return new Date().getFullYear();
  }

  openExternalLink(event: Event): void {
    // Prevent the default behavior of the click event
    event.preventDefault();

    // open the external link in the active tab
    window.location.href = environment.coreUrl;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.opened = true;
    this.showTitle = false;
    // Subscribe to navigation data
    this._navigationService.navigation$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((navigation: Navigation) => {
        this.navigation = navigation;
      });

    // // Subscribe to media changes
    // this._fuseMediaWatcherService.onMediaChange$
    //   .pipe(takeUntil(this._unsubscribeAll))
    //   .subscribe(({ matchingAliases }) => {
    //     // Check if the screen is small
    //     this.isScreenSmall = !matchingAliases.includes('md');
    //   });
  }

  getOpened(event: any) {
    console.log('========= ', event);
    this.showTitle = event;
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle navigation
   *
   * @param name
   */
  toggleNavigation(name: string): void {
    // Get the navigation
    const navigation =
      this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(
        name
      );

    if (navigation) {
      // Toggle the opened status
      navigation.toggle();
    }
    const isOpened = navigation.opened;  
    console.log('Navigation is opened:  this.opened', isOpened);  
  }
}
