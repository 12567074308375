import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { Router } from '@angular/router';
import {
  ApiRobot,
  Event,
  ListQuery,
  MqttSettings,
  ResponseList,
  Robot,
} from 'api-services';
import { AppService } from 'app/app.service';
import { RobotDetail } from 'app/modules/dashboard/robot-detail/robot-detail.component';
import { ApiEventDetection, EventWithImage } from 'app/services/event.service';
import _ from 'lodash';
import moment from 'moment';
import {
  BehaviorSubject,
  concatAll,
  first,
  map,
  Observable,
  scan,
  Subscription,
  tap,
} from 'rxjs';
import { NIL as NIL_UUID } from 'uuid';

@Component({
  selector: 'app-detection-panel',
  templateUrl: './detection-panel.component.html',
  styleUrls: ['./detection-panel.component.scss'],
})
export class DetectionPanelComponent implements OnInit, OnDestroy {
  @ViewChild('selectRobot') selectRobot: MatSelect;
  @ViewChild(CdkVirtualScrollViewport) virtualScroll: CdkVirtualScrollViewport;

  socketRmEventSub: Subscription;
  newEvent: EventWithImage;
  allEvent: Event[];
  totalEvent: number = 0;
  pageNo: number = 1;
  pageSize: number = 10;
  selectedLayoutId: string = '';
  isLoading = true;
  dataLayout;
  latestEventDate;

  //Filter Robot
  robotData: Robot[] = []; //list Robot data
  selectedRobotData: Robot | RobotDetail;
  robotTotal: number = 0;
  robotPageNumber: number = 1;
  robotPageSize: number = 10;
  robotOffset: number = 0;
  options = new BehaviorSubject<Robot[]>([]);
  options$: Observable<Robot[]>;
  selectedRobotId: string | RobotDetail = 'allRobots'; //used as default/update value of selected robotId

  titleDetectionPanel: string = 'Detections by all robots';
  isFilteredEvents: boolean = false;

  private intervalSub: Subscription;
  readonly POLLING_INTERVAL_MS = 5000;

  constructor(
    private _mqttSettings: MqttSettings,
    private _apiRobot: ApiRobot,
    private apiEvent: ApiEventDetection,
    private router: Router,
    private appService: AppService
  ) {
    this.options$ = this.options.asObservable().pipe(
      scan((acc, curr) => {
        return [...acc, ...curr];
      }, [])
    );
  }

  ngOnInit(): void {
    this.getRobotToSelect();

    this.getAllEvents();

    //filter robot from selected robot in robot-details-component
    this.apiEvent.filterEventBySelectedRobot.subscribe((value) => {
      //assignt the data robot from replay subject to selectedRobotData
      this.selectedRobotData = value;

      //fetch the event base on the selected robot
      this.onClickRobot(value, true);
    });

    //subscribe to the mqtt of rm event topic
    //the mqtt was from the BE after BE successfully create the event
    this.socketRmEventSub = this._mqttSettings.socketRmEventData$.subscribe(
      (data: EventWithImage) => {
        //check if the severity of the item !== 0 (event without severity)
        // check if the selected robot is allRobots OR the same as the filtered selected robot
        // then add or remove the existing data in allEvent base on the mqtt eventId
        if (
          data.severity !== 0 &&
          (this.selectedRobotId === 'allRobots' ||
            this.selectedRobotId === data.robotId)
        ) {
          // (if) the data from mqtt is new (have isNew property) event AND the event id is New (not duplicated with existing id event)
          // then add the new mqtt to allEvent
          // (else) if the new mqtt data is updated event (resolved event)
          // then remove the existing data in allEvent base on the mqtt eventId
          if (
            data.isNew &&
            !this.allEvent.find((item) => item.id === data.id)
          ) {
            let latestEvent = data;
            latestEvent.id = data.eventId;
            latestEvent.layoutName = data.location;
            //get and assign the robot name base on robot id
            this.getName(data.robotId, 'robot', (result: string) => {
              //assign the result of the api get robot
              latestEvent.robotName = result;
            });
            // show the timestamp base on value from the robot
            latestEvent.timestamp = new Date(data.date).getTime();
            //assign the new data with loaded image
            latestEvent = this.apiEvent.loadEventsImage(data);

            //final check if there is latest mqtt data
            //then assign the latest event to the top of the array
            //add total event counter
            //play sound and scroll the list to the top
            if (latestEvent) {
              this.allEvent = [latestEvent, ...this.allEvent];
              this.totalEvent++;
              this.playSound();
              this.virtualScroll.scrollToIndex(0);
            }
          } else {
            // Find the object in the array with id of the allEvent the same with the id of updated mqtt event data
            const index = this.allEvent.findIndex(
              (item) => item.id === data.eventId
            );

            // If the object was found, remove it from the array
            // then assign the new data
            if (index !== -1) {
              this.allEvent.splice(index, 1);
              // need to use immutability through the spread operator which results in the rendered view getting updated
              // without spread operator the new data not updated because the data rendered using cdkVirtualFor
              this.allEvent = [...this.allEvent];
              //reduce total event counter
              this.totalEvent--;
            }
          }
        }
      }
    );
  }

  scrolledIndexChange(itemNo: number) {
    // only load when the itemNo is almost at the last item.
    // after loading, increment the page number
    const maxItemNo = this.pageNo * this.pageSize;
    if (itemNo < maxItemNo - 5) return;

    if (this.allEvent.length >= this.totalEvent) return;

    if (!this.isFilteredEvents) {
      this.getAllEvents();
    } else {
      this.getAllEvents(true);
    }
    this.pageNo++;
  }

  //fetch the event from DB
  //the event is filter by (set in json filter-dashboard-data-by-time))
  public getAllEvents(filterByRobot: boolean = false): void {
    //first get the startDate from json filter-dashboard-data-by-time
    this.apiEvent.getEventFilterTime().then((response) => {
      const startDate = moment()
        .subtract(response.timeValue, response.timeType)
        .format('YYYY-MM-DD HH:mm:ss');

      const payload = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        order: [
          {
            name: 'CreatedTime',
            column: 'events.created_at',
            type: 'desc' as const,
          },
        ],
        filter: [
          {
            column: 'events.status',
            dataType: 'enum',
            extra: 'data_dict("EventStatus")',
            name: 'Status',
            operator: 'eq',
            value: ['2'],
            virtual: false,
          },
          {
            column: 'created_at',
            dataType: 'datetime',
            extra: 'pattern:"YYYY-MM-DD"',
            name: 'Start Date',
            operator: 'ge',
            value: [startDate],
            virtual: false,
          },
          // filter out items without a severity (severity=0)
          {
            column: 'severity',
            dataType: 'enum',
            extra: 'data_dict("EventSeverity")',
            name: 'Severity',
            operator: 'ne',
            value: ['0'],
            virtual: false,
          },
        ],
      };

      if (filterByRobot) {
        const filterRobot = {
          name: 'robotId',
          column: 'robot_id',
          operator: 'eq',
          value: [this.selectedRobotData?.id],
          extra: '',
          dataType: 'text',
          virtual: false,
        };
        payload.filter = [...payload.filter, filterRobot];
      }

      let tempEventList: ResponseList<EventWithImage>;

      this.apiEvent
        .list(payload)
        .pipe(
          tap((result) => {
            // keep the current response, so it can be called later
            tempEventList = result as ResponseList<EventWithImage>;
          }),
          map((response: ResponseList<EventWithImage>) => {
            // modify list of the response
            // it will get the list of event
            response.result.list.map((data: EventWithImage) => {
              //get and assign the robot name base on robot id
              this.getName(data.robotId, 'robot', (result: string) => {
                //assign the result of the api get robot
                data.robotName = result;
              });
              // data.timestamp = new Date(data.createdAt).getTime();
              data.timestamp = new Date(data.date).getTime(); // show the timestamp base on value from the robot

              // get the image of the image event from the api
              return this.apiEvent.loadEventsImage(data);
            });

            // create new ResponseList object from modified data
            const result: ResponseList<EventWithImage> = {
              ...tempEventList,
            };

            result.result.list = response.result.list;
            return result;
          })
          // delay(4000)
        )
        .subscribe((response) => {
          if (response.code === 200) {
            this.isLoading = false;
            //assign the result of the event api that have been modified to listEvent
            const listEvent = response.result.list;

            //assign the total number of events that not resolved to totalEvent
            this.totalEvent = response.result.totalRecords;

            // check if this.allEvent have value or not
            // if this.allEvent is undefined then assign the listEvent to the this.allEvent (for the first time)
            // else, concat the existing this.allEvent with the new fetch event data
            if (!this.allEvent || this.allEvent.length === 0) {
              this.allEvent = listEvent;
              this.totalEvent = response.result.totalRecords;
            } else {
              this.allEvent = [...this.allEvent, ...listEvent];
            }
          }
        });
    });
  }

  // get the name of the robot from the api get by their id
  // use resolver to get the data of robot detail from API to prevent multiple API call
  getName(id: string, nameType: 'robot', callback): void {
    if (nameType === 'robot' && id && id !== NIL_UUID) {
      this.appService.allRobots$
        .pipe(
          map((response) => {
            return response.result.list.filter((robot) => robot.id === id);
          }),
          concatAll(),
          first()
        )
        .subscribe((data) => {
          callback(data.name);
        });
    }
  }

  public goToDetectionDetail(id: string) {
    this.router.navigate(['/detection/details', id], {
      queryParams: { source: 'dashboard' },
    });
  }

  private playSound() {
    const audio = new Audio();
    audio.src = 'assets/audio/detection/new-detection.mp3';
    audio.load();
    audio.play();
  }

  getRobotToSelect(): void {
    const payload: ListQuery = {
      pageNo: this.robotPageNumber,
      pageSize: this.robotPageSize,
      order: [
        {
          name: 'Name',
          column: 'robots.name',
          type: 'asc' as const,
        },
      ],
      filter: [],
    };

    this._apiRobot.list(payload).subscribe((response) => {
      // this.robotData = response.result.list;
      //coment salah
      // check if this.allEvent have value or not
      // if this.allEvent is undefined then assign the listEvent to the this.allEvent (for the first time)
      // else, concate the exsiting this.allEvent with the new fetch event data
      if (!this.robotData) {
        this.robotData = response.result.list;
      } else {
        this.robotData = [...this.robotData, ...response.result.list];
      }
      this.robotTotal = response.result.totalRecords;
      // this.options.next(response.result.list);
      this.options.next(response.result.list);
      this.robotOffset += this.robotPageSize;
      this.robotPageNumber++;
    });
  }

  onClickRobot(event: string | RobotDetail, fromRobotDetail: boolean = false) {
    if (event === 'allRobots') {
      this.isLoading = true;
      this.pageNo = 1;
      this.allEvent = [];
      this.totalEvent = 0;
      this.isFilteredEvents = false;
      this.titleDetectionPanel = 'Detections by all robots';
      this.getAllEvents();
      this.selectedRobotId = event;
    } else {
      this.isFilteredEvents = true;
      //check if the filter from robot-details-component or not
      if (!fromRobotDetail) {
        //get robot data base on selected robot id (from detection panel)
        this.selectedRobotData = this.robotData.find(
          (result) => result['id'] === event
        );
        this.selectedRobotId = event;
      } else {
        this.selectedRobotId = event['id'];
      }
      this.titleDetectionPanel = this.selectedRobotData.name;
      this.isLoading = true;
      this.pageNo = 1;
      this.allEvent = [];
      this.totalEvent = 0;
      this.getAllEvents(true);
    }
  }

  toggleRobotSelection(): void {
    this.selectRobot.toggle();
  }

  ngOnDestroy(): void {
    if (this.socketRmEventSub) {
      this.socketRmEventSub.unsubscribe();
    }
  }
}
