import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { AppService } from 'app/app.service';
import { DashboardService } from 'app/modules/dashboard/dashboard.service';
import { JobListService } from 'app/modules/dashboard/joblist.service';
import { statusMapping } from 'app/modules/dashboard/robot-detail/robot-detail.component';
import {
  DispatchRobotPayload,
  RobotSocketData,
  RobotSocketState,
} from 'app/services/api.types';
import { NewJobService } from 'app/services/new-job.service';
import { UtilityService } from 'app/services/utility.service';
import {
  removeClassnameFromMultipleElement,
  removeClassnameToMultipleElementWithSpecificId,
} from 'app/shared/utils/robot-marker-util';
import moment from 'moment';
import {
  ApiRobot,
  ApiSkill,
  Layout,
  Mission,
  MqttSettings,
  ResponseList,
  Robot,
  Skill,
} from 'rm-api-services/dist/api-services';
import { interval, Subject, Subscription, takeUntil, throttle } from 'rxjs';
import { NIL as NIL_UUID } from 'uuid';

interface LayoutWithRobot {
  layoutId: string;
  layoutName: string;
  robots: MultileRobotJobCreation[];
}

interface MultileRobotJobCreation extends Robot {
  currentMissionName?: string;
}

@Component({
  selector: 'job-creation',
  templateUrl: './job-creation.component.html',
  styleUrls: ['./job-creation.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [JobListService],
})
export class JobCreationComponent implements OnChanges, OnInit, OnDestroy {
  @Input() layoutId?: string;
  @Input() siteId?: string;

  robotId: string;
  robot: Robot = null;
  robotSkills: Skill[];
  selectedSkill: Skill = null;
  toggleJobDrawer: boolean = false;
  jobs: Mission[] = [];
  typeCreateJob: string;
  robots: Robot[] = [];
  layoutWithRobots: LayoutWithRobot[] = [];
  selectedJobType: string;
  currentMission: Mission;
  statusNumberNotShow = [1, 2, 6];

  socketRobotSub: Subscription;
  socketRobotMissionSub: Subscription;
  socketRobotStateSub: Subscription;
  checkOfflineTimer;

  public isLoading: boolean = false;
  public isDisabled: boolean = false;

  private layoutName: string;
  private layoutList: Layout[] = [];
  private dispatchRobotPayload: DispatchRobotPayload;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  private eventsSubject: Subject<void> = new Subject<void>();
  constructor(
    private mqttSettings: MqttSettings,
    private _dashboardService: DashboardService,
    private _apiRobot: ApiRobot,
    private apiSkill: ApiSkill,
    private _utilityService: UtilityService,
    private newJobSrvc: NewJobService,
    private appService: AppService,
    private joblistService: JobListService
  ) {}

  ngOnInit(): void {
    this._dashboardService.selectedSkill$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((skill) => {
        this.toggleDrawerBar(skill);
      });

    this._dashboardService.selectedRobotId$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((robotId) => {
        this.robotId = robotId;
        this.robot = null;
        if (robotId) {
          this.getRobotDetail(robotId);
          this.getRobotSkills(robotId);
          this.joblistService.robotId$.next(robotId);
        }
      });

    this.joblistService.activeMissions$.subscribe((data) => {
      const tempJobList = data.map((job) => ({
        ...job,
        robots: this.formatRobots(job.robots),
        scheduledAt: job.scheduledAt || '',
        skills: this._utilityService.loadingSkillNames(job),
      }));
      this.jobs = tempJobList;
    });

    console.log('this.robotId :>> ', this.robotId);

    // used to check the type of job creation.
    // it can be for multiple robot or specific robot
    this._dashboardService.selectedTypeCreateJob$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((type) => {
        this.typeCreateJob = type;
      });

    // Used to get the selected robot when create job for multiple robot
    this._dashboardService.selectedRobots$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((robots) => {
        this.robots = robots;
        if (robots && robots.length > 0) {
          const robotIds = robots.map((robot) => robot.id);
          this.getLayoutList().then((layouts) => {
            this.layoutList = layouts;
            this.getSkillsByRobot(robotIds);
            this.getLayoutWithRobot();
          });
        }
      });

    // Used to get the selected job type. it can be 'patrol', 'goto', 'broadcast' or 'blinker'
    this._dashboardService.selectedJobType$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((jobType) => {
        this.selectedJobType = jobType;
        if (jobType === 'dispatch-robot') {
          this.populateDispatchRobotList();
        } else if (jobType === 'charge') {
          this.populateAllRobotList();
        }
      });

    this.socketRobotSub = this.mqttSettings.socketRobotData$.subscribe(
      (data: RobotSocketData) => {
        if (data) {
          if (data.robotId === this.robotId) {
            this.updateRobotDetails(data);
          }
        }
      }
    );

    // this.joblistService
    //   .updateJobListSubscribe(this.robotId)
    //   .subscribe((missions) => {
    //     this.jobs = missions;
    //   });

    this.checkOfflineTimer && clearInterval(this.checkOfflineTimer);
    this.checkOfflineTimer = setInterval(() => {
      if (this.robot) {
        const currenDate = new Date().getTime();
        const updatedAt = new Date(this.robot.updatedAt).getTime();
        const currentTime = moment(currenDate);
        const updatedTime = moment(updatedAt);
        const differenceInSeconds = currentTime.diff(updatedTime, 'seconds');
        if (updatedAt && differenceInSeconds > 10) {
          this.robot.status = 2;
          this.robot.updatedAt = moment(updatedAt).fromNow();
        }
      }
    }, 3 * 1000); //if robot is offline for 1 minute then show offline status

    this.socketRobotStateSub = this.mqttSettings.socketRbtStateData$.subscribe(
      (data) => {
        if (data) {
          if (data.robotId === this.robotId) {
            this.updatRobotState(data);
          }
        }
      }
    );

    //subscribe to the add job button to form submission to show/hide the loading icon
    this.newJobSrvc.isSubmited$.subscribe((isSubmited: boolean) => {
      this.isLoading = isSubmited;
    });

    this.newJobSrvc.isDisabled$.subscribe((isSubmited: boolean) => {
      this.isDisabled = isSubmited;
    });

    // Subscribe the dispatch robot payload
    this._dashboardService.dispatchRobotPayload$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((payload) => {
        this.dispatchRobotPayload = payload;
      });
  }

  ngOnChanges(change: SimpleChanges) {
    if (change.robotId) {
      this.getCurrentMission(change.robotId.currentValue);
    }
  }

  toggleDrawerBar(skill: Skill) {
    if (
      skill &&
      (this.robotId ||
        this.robots.length > 0 ||
        this.selectedJobType === 'dispatch-robot')
    ) {
      this.toggleJobDrawer = !this.toggleJobDrawer;
      this.selectedSkill = skill;
    }
  }

  emitEventToChild() {
    this.eventsSubject.next();
  }

  onToggleJobDrawer(event: any): void {
    this.toggleJobDrawer = event;
    if (!this.toggleJobDrawer && this.typeCreateJob !== 'multiple-robot') {
      this.resetType();
    }
  }

  getRobotDetail(id: string): void {
    this.appService.robots$.subscribe((robots) => {
      this.robot = robots.result.list.find((robot) => robot.id === id);
    });
  }

  //need to get list of object skill to used in concurent job
  getRobotSkills(id: string): void {
    this._apiRobot
      .getRobotSkills(id)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        if (res.code === 200) {
          if (res.result) {
            this.robotSkills = res.result;
          }
        } else {
          this.robotSkills = undefined;
          // this.handelError(res);
          console.log('Error', res);
        }
      });
  }

  /**
   * Helper function to filter the skill by robot list to get the skillId
   *
   * @param robotIds List of robots id
   */
  private getSkillsByRobot(robotIds: string[]): void {
    this.apiSkill
      .getSkillByRobotsId(robotIds)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        if (res['code'] === 200) {
          if (res['result']) {
            this.robotSkills = res['result'];
          }
        } else {
          this.robotSkills = undefined;
          // this.handelError(res);
          console.log('Error', res);
        }
      });
  }

  handleClose() {
    this.toggleJobDrawer = false;
    this._dashboardService.selectedSkillMultipleRobot$.next(null);
    this._dashboardService.selectedTypeCreateJob$.next(null);
    this._dashboardService.selectedRobotCoordinate$.next(null);
    this._dashboardService.selectedRobots$.next([]);
    this._dashboardService.isMultipleRobotJobDrawerOpened$.next(false);
    // Clear the active class in the selected robot if the drawer closed
    removeClassnameFromMultipleElement(
      'active',
      '.robot-icon',
      '.robot-container',
      '.bg-robot-logo'
    );
    this.resetType();
    this._dashboardService.selectedDispatchRobotId$.next(undefined);
  }

  /**
   * Helper function to remove the robot list on drawer.
   * It used when create job for multiple robot
   *
   * @param robotId removed robot ID
   */
  public removeRobotSelection(robotId: string): void {
    const selectedRobotIndex = this.robots.findIndex(
      (robot: Robot) => robot.id === robotId
    );

    if (selectedRobotIndex !== -1) {
      this.robots.splice(selectedRobotIndex, 1);
      removeClassnameToMultipleElementWithSpecificId(
        robotId + '_layout-map_robot',
        'active',
        '.robot-icon',
        '.robot-container'
      );
      removeClassnameToMultipleElementWithSpecificId(
        robotId,
        'active',
        '.robot-icon',
        '.bg-robot-logo'
      );
    }

    this.updateRobotListOnDrawer();
  }

  public selectMoreRobots(skill: Skill): void {
    this._dashboardService.selectedSkillMultipleRobot$.next(skill);
    this._dashboardService.selectedRobots$.next(this.robots);
    this.toggleJobDrawer = false;
  }

  public selectRobot(robotId: string): void {
    this.robotId = robotId;
    this.robot = null;
    if (robotId) {
      this.getRobotDetail(robotId);
      this.getRobotSkills(robotId);
      this.joblistService.robotId$.next(robotId);
    }
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  private getCurrentMission(id: string): void {
    this._dashboardService
      .getCurrentMission(id)
      .pipe(throttle(() => interval(5000)))
      .subscribe((response: ResponseList<Mission>) => {
        if (
          response.result &&
          response.result.list &&
          response.result.list.length > 0
        ) {
          this.currentMission = response.result.list[0];
          this.currentMission.skills = this._utilityService.loadingSkillNames(
            this.currentMission
          );
          this.currentMission.status =
            statusMapping[this.currentMission.status];
        } else {
          this.currentMission = null;
        }
      });
  }

  private formatRobots(robotsArr) {
    if (robotsArr && Array.isArray(robotsArr)) {
      if (robotsArr.length === 0) {
        return ['-'];
      } else {
        return robotsArr.filter((robot) => robot.name && robot.id);
      }
    } else {
      return ['-'];
    }
  }

  /**
   * Helper function mapping the layout and robot data to show in the drawer,
   * when multiple robot job creation drawer is opened if in the building,
   * it will map the the robot list for each layout in that building.
   * Otherwise it will show as one layout
   */
  private getLayoutWithRobot(): void {
    this.layoutWithRobots = [];
    if (!this.siteId && this.layoutId) {
      this.layoutWithRobots = [
        {
          layoutId: this.layoutId,
          layoutName: this.layoutName,
          robots: [],
        },
      ];
    } else {
      this.layoutList.map((layout) => {
        this.layoutWithRobots.push({
          layoutId: layout.id,
          layoutName: layout.name,
          robots: [],
        });
      });
    }

    this.updateRobotListOnDrawer();
  }

  /**
   * Helper function to get the list of layout or site from API
   * when the layout or site changed.
   * If the siteId exist, load the layout list where the parent
   * is the siteId to get all the layout that belong to the site.
   * Otherwise just load the data for given layoutId
   */
  private getLayoutList(): Promise<Layout[]> {
    return new Promise((resolve) => {
      if (this.siteId && this.siteId !== NIL_UUID) {
        this.appService.layouts$
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((response) => {
            if (response.code === 200) {
              const tempLayouts = this.sortLayoutsByName(
                response?.result?.list || []
              );
              const filteredLayouts = tempLayouts.filter(
                (layout) => layout.parentId === this.siteId
              );
              resolve(filteredLayouts);
            } else {
              resolve([]);
            }
          });
      } else if (!this.siteId && this.layoutId) {
        // just load the data for given layoutId if there is no siteId
        this.appService.layouts$
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((res) => {
            const tempLayouts = res?.result?.list || [];
            const layoutSelected = tempLayouts.find(
              (layout) => layout.id === this.layoutId
            );
            if (layoutSelected) {
              this.layoutName = layoutSelected.name;
            }
            resolve([]);
          });
      } else if (!this.siteId && !this.layoutId) {
        this.appService.layouts$
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((response) => {
            const tempLayouts = this.sortLayoutsByName(
              response?.result?.list || []
            );
            const filteredLayouts = tempLayouts.filter(
              (layout) => layout.type === 1
            );
            resolve(filteredLayouts);
          });
      }
    });
  }

  // update robot status by socket
  private updateRobotDetails(socketData: RobotSocketData) {
    if (socketData && this.robot) {
      if (socketData.timestamp.toString().length > 10) {
        this.robot.lastOnlineTime = moment(socketData.timestamp).fromNow();
      } else {
        this.robot.lastOnlineTime = moment.unix(socketData.timestamp).fromNow();
      }
      this.robot.status = 1;
      this.robot.battery = Number(socketData.battery);
      this.robot.connectivity = socketData.connectivity;
      this.robot.updatedAt = this._utilityService.formatDate(
        new Date().toLocaleString()
      );
    }
  }

  /**
   * Helper function to show the robot list on drawer when create job for multiple robot
   */
  private updateRobotListOnDrawer(): void {
    // sort the robot name in the list
    this.robots.sort((a, b) =>
      a.name > b.name ? 1 : b.name > a.name ? -1 : 0
    );

    // mapping the robot list based on layout id in to the layout list
    // when user select or unselect the robot
    this.layoutWithRobots = this.layoutWithRobots.map((layout) => {
      layout.robots = [];
      this.robots.map((robot) => {
        if (robot.layoutId === layout.layoutId) {
          layout.robots.push(robot);
        }
      });

      return layout;
    });
  }

  private updatRobotState(socketData: RobotSocketState): void {
    if (this.robot && socketData) {
      this.robot.state = socketData.state;
    }
  }

  private sortLayoutsByName(layouts: Layout[]): Layout[] {
    return layouts.sort((a, b) => a.name.localeCompare(b.name));
  }

  public resetType(): void {
    this.selectedSkill = null;
    this._dashboardService.selectedSkill$.next(null);
    this._dashboardService.selectedJobType$.next(null);
  }

  private populateDispatchRobotList(): void {
    if (!this.dispatchRobotPayload) {
      return;
    }

    const parsedLocation = JSON.parse(this.dispatchRobotPayload.location);

    this._apiRobot
      .list({
        pageNo: 1,
        pageSize: 100,
        quickOrder: [
          {
            point: {
              x: parsedLocation.x,
              y: parsedLocation.y,
            },
          },
        ],
      })
      .subscribe((response) => {
        const robotList = response.result.list || [];
        const filteredRobot = robotList.filter(
          (robot) => robot.layoutId === this.dispatchRobotPayload.layoutId
        );

        this.robots = filteredRobot;
      });
  }

  private populateAllRobotList(): void {
    this._apiRobot
      .list({
        pageNo: 1,
        pageSize: 100,
      })
      .subscribe((response) => {
        // exclude the selected robot
        this._dashboardService.selectedRobotId$
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((robotId) => {
            if (robotId) {
              response.result.list = response.result.list.filter(
                (robot) => robot.id !== robotId
              );
            }
          });
        const robotList = response.result.list || [];
        this.robots = robotList;
      });
  }
}
