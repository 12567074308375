import { FormControl } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { isForbidden } from 'app/shared/directives/validator.directive';

@Component({
  selector: 'shared-form-textarea',
  template: `<div class="w-full">
    <mat-label class="block mb-2 text-gray-900 dark:text-white" *ngIf="label">{{
      label
    }}</mat-label>
    <textarea
      class="rounded w-full bg-neutral-700 p-3 border-[1px]"
      [ngClass]="{
        'h-50': !height,
        'border-[#dc2626]': control && control?.invalid && control?.touched,
        'border-neutral-400': !(control && control?.invalid && control?.touched)
      }"
      [placeholder]="placeholder"
      [formControl]="control ? control : ''"
      (input)="handleSelect($event)"
      [style]="{
        height: height ? height + 'px' : '',
        resize: disableResize ? 'none' : 'auto'
      }"
    ></textarea>
    <!-- form not valid -->
    <div
      *ngIf="control && control?.invalid && control?.touched"
      class="text-[#dc2626] mt-px"
    >
      <div *ngIf="control?.errors" class="flex items-center">
        <mat-icon
          class="scale-75 text-error-300"
          svgIcon="mat_solid:warning"
        ></mat-icon>
        <!-- Error required input -->
        <ng-container *ngIf="control?.errors?.required">
          This field is required
        </ng-container>
        <!-- Error invalid input -->
        <ng-container
          *ngIf="!control?.errors?.required && control?.errors?.invalid"
        >
          This field is invalid
        </ng-container>
      </div>
    </div>
    <div *ngIf="isInvalid" class="text-[#dc2626] mt-px">
      <div *ngIf="isInvalid">
        <mat-icon
          class="scale-75 text-error-300"
          svgIcon="mat_solid:warning"
        ></mat-icon>
          This field contains invalid characters.
      </div>
    </div>

  </div>`,
})
export class FormTextareaComponent implements OnInit {
  @Input() label: string = null;
  @Input() placeholder: string = null;
  @Input() control: FormControl;
  @Input() height: number; // in pixel
  @Input() disableResize: boolean = false;
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();
  isInvalid: boolean = false;
  ngOnInit(): void {}
  public handleSelect(event: any): void {
    this.isInvalid = !isForbidden(event.target.value);
    this.valueChange.emit(event.target.value);
  }
}
