import { ElementRef, Injectable } from '@angular/core';
import { DashboardService } from 'app/modules/dashboard/dashboard.service';
import L, {
  divIcon,
  imageOverlay,
  LatLngBoundsExpression,
  layerGroup,
  Map,
  marker,
  polygon,
  polyline,
} from 'leaflet';
import { get } from 'lodash';
import {
  MapRobotMarker,
  MarkerType,
  MarkerTypeData,
  MarkerTypeDatas,
  PointRegion,
  Region,
} from './api.types';
import { moveToPosition } from 'app/shared/utils/robot-marker-util';
import { AntPath } from 'leaflet-ant-path';

const OVERLAY_IMAGE_WIDTH = 3000;
const OVERLAY_IMAGE_HEIGHT = 1500;

interface ImageDimension {
  width: number;
  height: number;
}

interface RmMarkerOptions extends L.MarkerOptions {
  markerId: string;
  color?: string;
  iconId?: string;
  click?: L.LeafletEventHandlerFn;
  dragstart?: L.LeafletEventHandlerFn;
  dragend?: L.LeafletEventHandlerFn;
  label?: string;
}

interface PointMarker {
  id?: string;
  x: number;
  y: number;
  z: number;
}

interface RmPolylineOptions extends L.PolylineOptions {
  name?: string;
  markerId?: string;
  color?: string;
  click?: L.LeafletEventHandlerFn;
  dragstart?: L.LeafletEventHandlerFn;
  dragend?: L.LeafletEventHandlerFn;
}

interface ZoneArea extends Omit<Region, 'coordinates' | 'metadata'> {
  coordinates: PointRegion[];
  color: string;
}

@Injectable({
  providedIn: 'root',
})
export class LeafletService {
  protected map: L.Map = null;
  protected mapZoom: number;

  protected _markersGroup = layerGroup();
  protected _robotMarkersGroup = layerGroup();
  protected _eventMarkersGroup = layerGroup();
  protected _sensorMarkersGroup = layerGroup();
  protected _graphMarkersGroup = layerGroup();
  protected _trafficLanesGroup = layerGroup();
  protected _plannedPathGroup = layerGroup();
  protected _zonesGroup = layerGroup();
  protected _markers: { [markerId: string]: L.Marker } = {};
  protected _trafficLanes: { [lineId: string]: L.Polyline } = {};
  protected _plannedPath: { [pathId: string]: AntPath } = {};
  protected _zones: { [zoneId: string]: L.Polygon } = {};
  protected _image: ImageDimension = {
    width: OVERLAY_IMAGE_WIDTH,
    height: OVERLAY_IMAGE_HEIGHT,
  };
  protected updateRobotPositionFrame: { [robotId: string]: any } = {};
  protected imageBounds: LatLngBoundsExpression;

  // use to check if the zoom event triger using the zoom tool (button / slider) or mouse scroll or pitch gesture
  protected isUseZoomTool: boolean = false;

  protected TRAFFIC_ENGINE_PANE = 'traffic-engine-pane';

  protected MAP_TYPE = 'leaflet';
  protected MARKER_TYPE = {
    marker: 'marker',
    robot: 'robot',
    event: 'event',
    layoutSensor: 'layout-sensor',
    trafficGraph: 'traffic-graph',
    trafficGraphMarker: 'traffic-graph-marker',
    teleopRobot: 'teleop-robot',
    zoneArea: 'zone',
    dispatchMarker: 'dispatch-marker',
    charging: 'charging-point',
    alarmMarker: 'alarm-marker'
  };

  protected AVAILABLE_ZONE_AREA_COLOR = {
    blue: '#4c9aff',
    red: '#ff7452',
    yellow: '#ffc400',
    grey: '#bfbfbf',
    violet: '#8777d9',
    green: '#2aad27',
    indigo: '#04b4cd',
  };

  constructor(protected dashboardService: DashboardService) {}

  /**
   * Helper function to init map using Maplibre library.
   * After map initiated, it will set function when
   * map load, zooming, & finish zooming
   *
   * @param mapRef Map reference for maplibre
   * @param options Leaflet Map Options
   * @param isMiniLayout used to check if init map for mini layout or not (true / false)
   */
  public initMap(mapRef: ElementRef, options: L.MapOptions): void {
    const zoom = localStorage.getItem('zoomLeaflet');

    this.mapZoom = (zoom && Number(zoom)) || 2.5;
    this.dashboardService.mapZoom$.next(this.mapZoom);
    this.map = new Map(mapRef.nativeElement, {
      ...options,
      zoom: this.mapZoom,
    });

    this.map.setMinZoom(-2);
    this.map.setMaxZoom(5);
    this.map.addLayer(this._markersGroup);
    this.map.addLayer(this._robotMarkersGroup);
    this.map.addLayer(this._eventMarkersGroup);
    this.map.addLayer(this._sensorMarkersGroup);
    this.map.addLayer(this._graphMarkersGroup);
    this.map.addLayer(this._trafficLanesGroup);
    this.map.addLayer(this._plannedPathGroup);
    this.map.addLayer(this._zonesGroup);

    // set current zoom level for slider when user zoom the map
    // using mouse scroll or pinch gesture, so it will reflect
    // the zoom tool slider thumb position.
    this.map.on('zoom', () => {
      if (!this.isUseZoomTool) {
        this.mapZoom = this.map.getZoom();
        this.dashboardService.mapZoom$.next(this.mapZoom);
      }
    });

    // set the variable isUseZoomTool to false after zoom finish,
    // so when user use mouse scroll or pitch gesture it will
    // reflect the zoom tool slider thumb position.
    // Then save the current zoom level to local storage.
    this.map.on('zoomend', () => {
      if (this.isUseZoomTool) {
        this.isUseZoomTool = false;
      }

      const zoom = this.map.getZoom();
      localStorage.setItem('zoomLeaflet', `${zoom}`);
    });

    // create the pane for traffic engine path
    const myMap = this.map;
    myMap.createPane(this.TRAFFIC_ENGINE_PANE);
    myMap.getPane(this.TRAFFIC_ENGINE_PANE).style.zIndex = '401';
  }

  /**
   * Helper function used to set checking varibale if the zoom event triger
   * using the zoom tool (button / slider) or mouse scroll or pitch gesture
   *
   * @param value true / false
   */
  public setIsUseZoomTool(value: boolean): void {
    this.isUseZoomTool = value;
  }

  /**
   * Helper function to zoom in the map
   *
   * @param delta number of zoom level (optional)
   * @param options zoom options animation used in leaflet (optional). https://leafletjs.com/reference.html#zoom-options
   */
  public zoomIn(delta?: number, options?: L.ZoomOptions): void {
    this.map.zoomIn(delta, { ...options });
  }

  /**
   * Helper function to zoom out the map
   *
   * @param delta number of zoom level (optional)
   * @param options zoom options animation used in leaflet (optional). https://leafletjs.com/reference.html#zoom-options
   */
  public zoomOut(delta?: number, options?: L.ZoomOptions): void {
    this.map.zoomOut(delta, { ...options });
  }

  /**
   * Helper function to zoom map by specific zoom level
   *
   * @param zoom specific zoom level
   * @param options Animation option when scrolling (optional). https://leafletjs.com/reference.html#zoom/pan-options
   */
  public setZoom(zoom: number, options?: L.ZoomPanOptions): void {
    this.map.setZoom(zoom, { ...options });
  }

  /**
   * Helper function to load map image and render to the container and set the map edge
   *
   * @param images layout map images
   * @param width images width
   * @param height images height
   */
  public loadMapImage(images: string, width?: number, height?: number): void {
    this._image.width = width || OVERLAY_IMAGE_WIDTH;
    this._image.height = height || OVERLAY_IMAGE_HEIGHT;

    this.imageBounds = this.getImageBounds();
    imageOverlay(images, this.imageBounds).addTo(this.map);
    this.map.fitBounds(this.imageBounds);
    this.map.setZoom(this.mapZoom);
  }

  /**
   * Helper function to get the edge of the image
   *
   * @param offset offset number of image
   * @returns
   */
  public getImageBounds(offset: number = 0): L.LatLngBounds {
    const width = this._image.width;
    const height = this._image.height;
    const southWest = this.map.unproject([0 - offset, height + offset], 1);
    const northEast = this.map.unproject([width + offset, 0 - offset], 1);
    return new L.LatLngBounds(southWest, northEast);
  }

  /**
   * Add marker to a map from array marker
   *
   * @param lMarkers marker data to be rendered
   * @param type marker type ('marker' or 'robot')
   * @param options options for leaflet marker
   */
  public renderLayoutMarkers(
    lMarkers: MarkerTypeDatas,
    type: MarkerType,
    options: Partial<RmMarkerOptions>
  ): void {
    lMarkers.forEach((lMarker) => {
      if (type === this.MARKER_TYPE.marker && !lMarker['position']) {
        return;
      } else if (type === this.MARKER_TYPE.robot && !lMarker['point']) {
        return;
      } else if (type === this.MARKER_TYPE.event && !lMarker['layout']) {
        return;
      } else if (
        type === this.MARKER_TYPE.layoutSensor &&
        !lMarker['coordinate']
      ) {
        return;
      } else if (
        type === this.MARKER_TYPE.trafficGraph &&
        !lMarker['x'] &&
        !lMarker['y']
      ) {
        return;
      } else if (
        type === this.MARKER_TYPE.trafficGraphMarker &&
        !lMarker['x'] &&
        !lMarker['y']
      ) {
        return;
      }

      this.renderLayoutMarker(lMarker, type, options);
    });
  }

  /**
   * Add marker to a map
   *
   * @param lMarker marker data
   * @param type marker type ('marker' or 'robot')
   * @param options Leaflet Marker Options
   */
  public renderLayoutMarker(
    lMarker: MarkerTypeData,
    type: MarkerType,
    options: Partial<RmMarkerOptions>
  ): void {
    // first, try to find if layout Marker is already in list of markers.
    // if no, create new marker. Else, update the leaflet marker
    const found = this.findMarkerFromLayoutMarker(lMarker, type);

    if (found) {
      // update the marker
      this.updateMarker(found, lMarker, type);
      return;
    }

    // Create marker. Then, set the icon and color
    const lfMarker = this.createMarker(lMarker, type, options);
    this.setMarkerIcon(
      lfMarker,
      type,
      lMarker.id,
      lMarker['name'] || lMarker['title'],
      lMarker['detectionNumber'],
      lMarker['heading'],
      lMarker['blinking'],
      lMarker['malfunctionNumber'],
      false,
      lMarker['type']
    );
  }

  public latlngToXy(latlng: L.LatLng): L.Point {
    return this.map.project(latlng, 1);
  }

  connectChargerToGateway(node, chargePointCoordinate): void {
    const nodeLatlng = this.xYtoLatlng([node.x, node.y]);
    const chargePointLatlng = this.xYtoLatlng([
      chargePointCoordinate.x,
      chargePointCoordinate.y,
    ]);

    const dashedLane = polyline(
      [
        { lat: nodeLatlng.lat, lng: nodeLatlng.lng },
        { lat: chargePointLatlng.lat, lng: chargePointLatlng.lng },
      ],
      {
        color: '#8DBFB3', //use the color for single lane, because only 1 robot can utilize this at a time
        weight: 4,
        opacity: 0.5,
        dashArray: '20, 20',
        dashOffset: '0',
      }
    );

    //add this dashed lane to the graph group
    this._graphMarkersGroup.addLayer(dashedLane);
  }

  /**
   * Helper function to parse (X, Y) corrdinate to (Lat, Lng) coordinate
   *
   * @param xy (X, Y) coordinate
   * @returns
   */
  public xYtoLatlng(xy: L.PointExpression): L.LatLng {
    return this.map.unproject(xy, 1);
  }

  /**
   * Helper function to remove marker from map
   *
   * @param refId Reference ID of the marker
   * @param type Marker type can be 'marker', 'robot or 'event'
   */
  public removeMarker(refId: string, type: MarkerType): void {
    const searchId = `${refId}_${this.MAP_TYPE}_${type}`;
    const selectedMarker = get(this._markers, searchId, '');
    if (selectedMarker) {
      switch (type) {
        case this.MARKER_TYPE.marker:
        case this.MARKER_TYPE.charging:
          this._markersGroup.removeLayer(selectedMarker);
          break;
        case this.MARKER_TYPE.robot:
          this._robotMarkersGroup.removeLayer(selectedMarker);
          break;
        case this.MARKER_TYPE.event:
          this._eventMarkersGroup.removeLayer(selectedMarker);
          break;
        case this.MARKER_TYPE.layoutSensor:
          this._sensorMarkersGroup.removeLayer(selectedMarker);
          delete this._markers[searchId];
          break;
        case this.MARKER_TYPE.trafficGraph:
          this._graphMarkersGroup.removeLayer(selectedMarker);
          delete this._markers[searchId];
          break;
      }
      delete this._markers[searchId];
    }
  }

  public removeLineMarker(refId: string): void {
    const searchId = `${refId}_${this.MAP_TYPE}`;
    const selectedMarker = get(this._trafficLanes, searchId, '');
    if (selectedMarker) {
      this._trafficLanesGroup.removeLayer(selectedMarker);
      delete this._trafficLanes[searchId];
    }
  }

  /**
   * Helper function to remove planned path from map
   *
   * @param refId Reference ID of the planned path
   */
  public removePlannedPath(refId: string): void {
    const searchId = `${refId}_${this.MAP_TYPE}`;
    const selectedMarker = get(this._plannedPath, searchId, '');
    if (selectedMarker) {
      this._plannedPathGroup.removeLayer(selectedMarker);
      delete this._plannedPath[searchId];
    }
  }

  public removeAllLineMarker(): void {
    this._trafficLanesGroup.clearLayers();
    this.map.removeLayer(this._trafficLanesGroup);
    this._trafficLanes = {};
  }

  public clear(): void {
    this._markersGroup.clearLayers();
    this._robotMarkersGroup.clearLayers();
    this._eventMarkersGroup.clearLayers();
    this._sensorMarkersGroup.clearLayers();
    this._graphMarkersGroup.clearLayers();
    this._trafficLanesGroup.clearLayers();
    this._plannedPathGroup.clearLayers();
    this._zonesGroup.clearLayers();
  }

  public reset(): void {
    // Because this service is a singleton,
    // don't clear the markers, as they are used in the layout map.
    // For future reference, try to implement this service not as a singleton.
    this.map.removeLayer(this._markersGroup);
    this.map.removeLayer(this._robotMarkersGroup);
    this.map.removeLayer(this._eventMarkersGroup);
    this.map.removeLayer(this._sensorMarkersGroup);
    this.map.removeLayer(this._graphMarkersGroup);
    this.map.removeLayer(this._trafficLanesGroup);
    this.map.removeLayer(this._plannedPathGroup);
    this.map.removeLayer(this._zonesGroup);
    this._markers = {};
    this._trafficLanes = {};
    this._plannedPath = {};
    this._zones = {};
    this.map = null;
  }

  public resetMarkerGroup(): void {
    this._markersGroup = layerGroup();
    this._robotMarkersGroup = layerGroup();
    this._eventMarkersGroup = layerGroup();
    this._sensorMarkersGroup = layerGroup();
    this._graphMarkersGroup = layerGroup();
    this._trafficLanesGroup = layerGroup();
    this._plannedPathGroup = layerGroup();
    this._zonesGroup = layerGroup();
    this.map.addLayer(this._markersGroup);
    this.map.addLayer(this._robotMarkersGroup);
    this.map.addLayer(this._eventMarkersGroup);
    this.map.addLayer(this._sensorMarkersGroup);
    this.map.addLayer(this._graphMarkersGroup);
    this.map.addLayer(this._trafficLanesGroup);
    this.map.addLayer(this._plannedPathGroup);
    this.map.addLayer(this._zonesGroup);
  }

  public setShowMarkers(showMarkers: boolean): void {
    if (showMarkers) {
      this.map.addLayer(this._markersGroup);
    } else {
      this.map.removeLayer(this._markersGroup);
    }
  }

  public setShowRobotMarkers(showMarkers: boolean): void {
    if (showMarkers) {
      this.map.addLayer(this._robotMarkersGroup);
      this.map.addLayer(this._plannedPathGroup);
    } else {
      this.map.removeLayer(this._robotMarkersGroup);
      this.map.removeLayer(this._plannedPathGroup);
    }
  }

  public setShowEventMarkers(showMarkers: boolean): void {
    if (showMarkers) {
      this.map.addLayer(this._eventMarkersGroup);
    } else {
      this.map.removeLayer(this._eventMarkersGroup);
    }
  }

  public setShowSensorMarkers(showMarkers: boolean): void {
    if (showMarkers) {
      this.map.addLayer(this._sensorMarkersGroup);
    } else {
      this.map.removeLayer(this._sensorMarkersGroup);
    }
  }

  public setShowTrafficGraph(showMarkers: boolean): void {
    if (showMarkers) {
      this.map.addLayer(this._graphMarkersGroup);
      this.map.addLayer(this._trafficLanesGroup);
    } else {
      this.map.removeLayer(this._graphMarkersGroup);
      this.map.removeLayer(this._trafficLanesGroup);
    }
  }

  public setShowZoneAreas(showMarkers: boolean): void {
    if (showMarkers) {
      this.map.addLayer(this._zonesGroup);
    } else {
      this.map.removeLayer(this._zonesGroup);
    }
  }

  /**
   * Helper function to remove map from container before render the new one
   *
   */
  public removeMap(): void {
    if (this.map && this.map.remove) {
      this.map.off();
      this.map.remove();
      this.reset();
      this.clear();
    }
  }

  /**
   * Helper function to move the center map to selected marker
   *
   * @param position (lat, Lng) coordinates of the marker
   */
  public flyTo(position: L.LatLng | L.LatLngExpression): void {
    this.map.flyTo(position);
  }

  /**
   * Helper function to check if map component is already initiate
   *
   * @returns boolean
   */
  public isMapInitiate(): boolean {
    return this.map ? true : false;
  }

  /**
   * Helper function to re render robot marker
   * repeatedly, as if making the marker move slowly using animation
   * and rotate the marker to make the marker facing toward direction
   *
   * @param robotMarker robot marker that want to updated
   */
  public updateRobotMarkerPosition(
    robotType: MarkerType,
    robotMarker: MapRobotMarker,
    moveFramePerSecond: number = 120,
    movePerPixel: number = 0.5
  ): void {
    // find the marker in the list
    const robot: L.Marker = this.findMarkerFromLayoutMarker(
      robotMarker,
      robotType
    );

    if (robot) {
      // get the old position and new position coordinate
      const fromPosition = robot.getLatLng();
      const toPosition = this.xYtoLatlng([
        robotMarker.point.x,
        robotMarker.point.y,
      ]);

      const refId = `${robotMarker.id}_${this.MAP_TYPE}_${robotType}`;
      // stop the current movement if the updated marker still moving
      if (this.updateRobotPositionFrame[refId]) {
        this.updateRobotPositionFrame[refId].stop();
      }
      // calculate the distance between old position and
      // new position of the marker, also calculate the angle between these points
      // then re render the marker based on updated marker position and angle
      this.updateRobotPositionFrame[refId] = moveToPosition(
        [fromPosition.lng, fromPosition.lat],
        [toPosition.lng, toPosition.lat],
        (position: [number, number]) => {
          const xLat = position[1];
          const yLng = position[0];

          if (!isNaN(xLat) && !isNaN(yLng)) {
            this.setRobotAngle(robotMarker.id, robotType, robotMarker.heading);
            robot.setLatLng([xLat, yLng]);
          }
        },
        moveFramePerSecond, // frame per second to move the marker. Higher number make the marker move faster and vice versa
        movePerPixel // number of pixels used to update the marker position for each loop
      );
    }
  }

  public updateRobotLocation(
    robotType: MarkerType,
    robotMarker: MapRobotMarker
  ): void {
    const robot: L.Marker = this.findMarkerFromLayoutMarker(
      robotMarker,
      robotType
    );

    if (robot && robotMarker.point) {
      const latLng = this.xYtoLatlng([
        robotMarker.point?.x,
        robotMarker.point?.y,
      ]);
      robot.setLatLng(latLng);
    }
  }

  /**
   * Render line between two link area point
   * @param startPoint - Start point of the line
   * @param endPoint - End point of the line
   * @param refId - Reference ID use by leaflet
   * @param options - Leaflet Line options
   */
  public renderLineMarker(
    startPoint: PointMarker,
    endPoint: PointMarker,
    refId: string,
    options: Partial<L.PolylineOptions>
  ): void {
    // create line for each point
    const startxy: L.PointTuple = [startPoint.x, startPoint.y];
    const endxy: L.PointTuple = [endPoint.x, endPoint.y];

    // unproject the Point to latlng using zoom level of 1
    const startlatlng = this.xYtoLatlng(startxy);
    const endlatlng = this.xYtoLatlng(endxy);
    // create line between two point
    const line = polyline([startlatlng, endlatlng], options);

    const markerId = `${refId}_${this.MAP_TYPE}`;
    // add to lines group
    this._trafficLanesGroup.addLayer(line);
    this.map.addLayer(this._trafficLanesGroup);
    // add to index
    this._trafficLanes[markerId] = line;
  }

  public isPlannedPathShow(): boolean {
    return this._plannedPath ? true : false;
  }

  /**
   * Helper function to render the ant path like line animation for given coordinates
   *
   * @param refId Reference ID of the path planner that used only for reference by leaflet library
   * @param coordinates List of point coordinate which is traversed by the line
   * @param options AntPath options which is the same as Leaflet Polyline options. https://leafletjs.com/reference.html#polyline-option
   */
  public renderPathPlanned(
    refId: string,
    coordinates: L.LatLng[],
    options: any
  ): void {
    // create line between two point
    const path = new AntPath(coordinates, options);

    const markerId = `${refId}_${this.MAP_TYPE}`;
    // add to lines group
    this._plannedPathGroup.addLayer(path);
    this.map.addLayer(this._plannedPathGroup);
    // add to index
    this._plannedPath[markerId] = path;
  }

  public renderTrafficEnginePath(refId: string, coordinates: L.LatLng[]): void {
    const myMap = this.map;
    // there's a bug in the ant path library that the line is not rendered when the zoom level is below 1.
    // The issue is logged in github.
    // If the zoom level is below 1, let's warn the developer and maybe in the future,
    // he can patch the bug in the ant path library.
    const zoom = this.map.getZoom();
    if (zoom < 1) {
      console.error('Zoom level is below 1, cannot animate the path');
    }

    // create line between two point
    const path = new AntPath(coordinates, {
      delay: 50,
      dashArray: [20, 30],
      weight: 5,
      // always make the color red per PO's request
      color: '#F43B13',
      hardwareAccelerated: true,
    });

    const pathOptions = { renderer: L.svg({ pane: this.TRAFFIC_ENGINE_PANE }) };
    path.setStyle(pathOptions);

    const markerId = `${refId}_${this.MAP_TYPE}`;
    this._plannedPathGroup.addLayer(path);
    myMap.addLayer(this._plannedPathGroup);
    // add to index
    this._plannedPath[markerId] = path;
  }

  /**
   * Helper function to update the blinking light indicator for robot marker
   * in teleop page when the blinking light toggle button is on.
   * It will update the dom marker class to show or hide the light indicator in the marker
   *
   * @param robotId ID of seletced robot that want to update
   * @param isBlinking status of the blinking light. true if it light on and false if light off
   */
  public updateBlinkingMarkerIndicator(
    refId: string,
    isBlinking: boolean
  ): void {
    const parent = document.getElementById(
      `${refId}_${this.MAP_TYPE}_${this.MARKER_TYPE.trafficGraph}`
    );
    if (parent) {
      const blinkingDom = parent.querySelector('.traffic-graph-blinking');
      if (isBlinking) {
        blinkingDom.classList.remove('hidden');
      } else {
        blinkingDom.classList.add('hidden');
      }
    }
  }

  /**
   * Add zone area to map
   *
   * @param zone
   * @param options
   * @returns
   */
  public renderZone(
    zone: ZoneArea,
    markerType: MarkerType,
    options: Partial<RmPolylineOptions>
  ): void {
    options = {
      ...options,
      markerId: zone.id,
      color: this.AVAILABLE_ZONE_AREA_COLOR[zone.color],
      name: zone.name,
    };

    const latlngs = zone.coordinates.map((point) => this.xYtoLatlng(point));
    const cfMarker = polygon(latlngs, options);
    if (options.click) {
      cfMarker.on('click', options.click);
    }
    if (options.dragstart) {
      cfMarker.on('dragstart', options.dragstart);
    }
    if (options.dragend) {
      cfMarker.on('dragend', options.dragend);
    }

    //add tooltip for the door name
    cfMarker.bindTooltip(options.name, {
      permanent: true,
      opacity: 1,
      direction: 'center',
      className: 'zone-label',
    });

    const refId = `${zone.id}_${this.MAP_TYPE}_${markerType}`;

    // add to markers group
    this._zonesGroup.addLayer(cfMarker);

    // add to the index
    this._zones[refId] = cfMarker;
  }

  isValidCoordinate(position: L.LatLngExpression): boolean {
    return this.getImageBounds().contains(position);
  }

  protected findMarkerFromLayoutMarker(
    lMarker: MarkerTypeData,
    markerType: MarkerType
  ): L.Marker | null {
    const refId = `${lMarker.id}_${this.MAP_TYPE}_${markerType}`;
    const selectedMarker = this._markers[refId];
    return selectedMarker ?? null;
  }

  protected updateMarker(
    lfMarker: L.Marker,
    lMarker: MarkerTypeData,
    type: MarkerType
  ) {
    this.setMarkerIcon(
      lfMarker,
      type,
      lMarker.id,
      lMarker['name'] || lMarker['title'],
      lMarker['detectionNumber'],
      lMarker['heading'],
      lMarker['blinking'],
      lMarker['malfunctionNumber'],
      false,
      lMarker['type']
    );

    let coordinates: L.PointTuple = [0, 0];
    switch (type) {
      case this.MARKER_TYPE.marker:
      case this.MARKER_TYPE.charging:
        coordinates = [lMarker['position']?.x, lMarker['position']?.y];
        break;
      case this.MARKER_TYPE.robot:
        coordinates = [lMarker['point']?.x, lMarker['point']?.y];
        break;
      case this.MARKER_TYPE.event:
        coordinates = [lMarker['layout']?.x, lMarker['layout']?.y];
        break;
      case this.MARKER_TYPE.layoutSensor:
        coordinates = [lMarker['coordinate']?.x, lMarker['coordinate']?.y];
        break;
      case this.MARKER_TYPE.trafficGraph:
      case this.MARKER_TYPE.trafficGraphMarker:
        coordinates = [lMarker['x'], lMarker['y']];
        break;
    }

    const latlng = this.xYtoLatlng(coordinates);
    lfMarker.setLatLng(latlng);
  }

  protected setMarkerIcon = (
    selectedMarker: L.Marker,
    type: MarkerType,
    markerId: string,
    label?: string,
    detectionNumber?: number,
    heading?: number,
    isBlinking?: boolean,
    malfunctionNumber?: number,
    isShowRobotSonar?: boolean,
    extraType?: string
  ): void => {
    let iconSize: L.PointExpression = [45, 45];
    let iconAnchor: L.PointExpression = [45 / 2, 45 / 2];

    switch (type) {
      case this.MARKER_TYPE.marker:
      case this.MARKER_TYPE.charging:
      case this.MARKER_TYPE.trafficGraphMarker:
        iconSize = [20, 29];
        iconAnchor = [20 / 2, 29];
        break;
      case this.MARKER_TYPE.layoutSensor:
        iconSize = [30, 30];
        iconAnchor = [30 / 2, 30 / 2];
        break;
      case this.MARKER_TYPE.trafficGraph:
        iconSize = [10, 10];
        iconAnchor = [10 / 2, 10 / 2];
        break;
    }

    const icon = divIcon({
      className: 'layout-marker',
      html: this.generateHtmlDomString(
        type,
        markerId,
        label,
        detectionNumber,
        heading,
        isBlinking,
        malfunctionNumber,
        false,
        extraType
      ),
      iconSize: iconSize,
      iconAnchor: iconAnchor,
    });
    selectedMarker.setIcon(icon);
  };

  protected createMarker(
    lMarker: MarkerTypeData,
    type: MarkerType,
    options: Partial<RmMarkerOptions>
  ): L.Marker {
    // create new marker
    let lPoint: L.PointTuple = [0, 0];
    switch (type) {
      case this.MARKER_TYPE.marker:
      case this.MARKER_TYPE.charging:
        if (lMarker['position']) {
          lPoint = [lMarker['position']?.x, lMarker['position']?.y];
        } else {
          lPoint = [lMarker['x'], lMarker['y']];
        }
        break;
      case this.MARKER_TYPE.robot:
        lPoint = [lMarker['point']?.x, lMarker['point']?.y];
        break;
      case this.MARKER_TYPE.event:
        lPoint = [lMarker['layout']?.x, lMarker['layout']?.y];
        break;
      case this.MARKER_TYPE.layoutSensor:
        lPoint = [lMarker['coordinate']?.x, lMarker['coordinate']?.y];
        break;
      case this.MARKER_TYPE.trafficGraph:
      case this.MARKER_TYPE.trafficGraphMarker:
        lPoint = [lMarker['x'], lMarker['y']];
        break;
    }

    options = {
      ...options,
      markerId: lMarker.id,
      iconId: lMarker['marker']?.icon ?? null,
      title: lMarker['name'] || lMarker['title'],
      color: lMarker['metadata']?.color ?? 'grey',
      zIndexOffset:
        type === this.MARKER_TYPE.robot ? 402 : options.zIndexOffset,
    };

    // unproject the Point to latlng using zoom level of 1
    const latlng = this.xYtoLatlng(lPoint);

    // create the marker and the events
    const lfMarker = marker(latlng, options);
    if (options.click) {
      lfMarker.on('click', options.click);
    }
    if (options.dragstart) {
      lfMarker.on('dragstart', options.dragstart);
    }
    if (options.dragend) {
      lfMarker.on('dragend', options.dragend);
    }

    const refId = `${lMarker.id}_${this.MAP_TYPE}_${type}`;
    // add to markers group
    switch (type) {
      case this.MARKER_TYPE.marker:
      case this.MARKER_TYPE.charging:
        this._markersGroup.addLayer(lfMarker);
        break;
      case this.MARKER_TYPE.robot:
        this._robotMarkersGroup.addLayer(lfMarker);
        break;
      case this.MARKER_TYPE.event:
        this._eventMarkersGroup.addLayer(lfMarker);
        break;
      case this.MARKER_TYPE.layoutSensor:
        this._sensorMarkersGroup.addLayer(lfMarker);
        break;
      case this.MARKER_TYPE.trafficGraph:
        this._graphMarkersGroup.addLayer(lfMarker);
        break;
    }
    // add to the index
    this._markers[refId] = lfMarker;

    return lfMarker;
  }

  /**
   * Helper function to generate HTML DOM used for render marker on the map
   * @param type Marker type can be 'marker', 'robot', 'event', or 'layout-sensor'
   * @param markerId Marker ID used as reference for leaflet map
   * @param label Label showed for each marker (optional)
   * @param detectionNumber detection number to showed in the marker. It is used in marker with type 'marker' or 'robot' (optional)
   * @param heading Heading angle robot faced when moving in the layout. It is used in marker with type 'robot' (optional)
   * @returns
   */
  protected generateHtmlDomString(
    type: MarkerType,
    markerId: string,
    label?: string,
    detectionNumber?: number,
    heading?: number,
    isBlinking?: boolean,
    malfunctionNumber?: number,
    isShowSonarRobot?: boolean,
    extraType?: string
  ): string {
    const isDetection = detectionNumber && detectionNumber > 0;
    const isMalfunction = malfunctionNumber && malfunctionNumber > 0;
    const detectionStr =
      detectionNumber && detectionNumber > 1 ? 'detections' : 'detection';
    const malfuctionIcon = isMalfunction
      ? `<img class="w-4 detection-icon" src="/assets/images/markers/malfunction-robot-logo.svg">`
      : '';
    const detectionIcon = isDetection
      ? `<img class="w-4 detection-icon" src="/assets/images/markers/detection-logo.svg">`
      : '';
    const errorIcon =
      isDetection || isMalfunction
        ? `<span class="icon-container -mt-[25px] -ml-[42px] absolute flex items-center justify-center w-10 gap-2">
            ${detectionIcon}
            ${malfuctionIcon}
          </span>`
        : '';
    const detectionBlink = isDetection
      ? `<div class="detection-blinking robot"></div>`
      : '';
    let htmlString = '';
    switch (type) {
      case this.MARKER_TYPE.marker:
      case this.MARKER_TYPE.trafficGraphMarker:
        htmlString = `
        <div id="${markerId}_${this.MAP_TYPE}_${type}">
          <span class="marker-label normal">${label ?? ''}</span>
          <div class="marker normal">
            <div class="marker-icon"></div>
          </div>
          <div class="marker-shadow"></div>
        </div>
    `;
        break;
      case this.MARKER_TYPE.robot:
        htmlString = `
        <div id="${markerId}_${this.MAP_TYPE}_${type}">
          <span class="robot-label ${
            isDetection || isMalfunction ? 'detection' : 'normal'
          }">
            ${errorIcon}
            ${label ?? ''}
            <span class="robot-detection">${
              isDetection ? detectionNumber : ''
            } ${isDetection ? detectionStr : ''}</span>
          </span>
          <div class="robot-container ${
            isDetection || isMalfunction ? 'detection' : 'normal'
          }">
            <div class="robot ${
              isDetection || isMalfunction ? 'detection' : 'normal'
            }">
              <div class="robot-icon ${
                isDetection || isMalfunction ? 'detection' : 'normal'
              }"></div>
            </div>
          </div>
          <div class="robot-sonar ${
            isDetection || isMalfunction ? 'detection' : 'normal'
          }" style="transform-origin: center bottom 0px; transform: rotate(${
          heading ?? 0
        }deg);"></div>
        ${detectionBlink}
        <div class="dispatch-blinking robot hidden"></div>
        </div>
    `;
        break;
      case this.MARKER_TYPE.event:
      case this.MARKER_TYPE.layoutSensor:
        if (extraType === 'Camera') {
          htmlString = `
          <div id="${markerId}_${this.MAP_TYPE}_${type}">
            <span class="${type}-label">${label ?? ''}</span>
            <div class="${type}-camera-icon"></div>
            <div class="${type}-camera"></div>
          </div>
      `;
        } else {
          htmlString = `
            <div id="${markerId}_${this.MAP_TYPE}_${type}">
              <span class="${type}-label">${label ?? ''}</span>
              <div class="${type}-icon"></div>
              <div class="${type}"></div>
            </div>
        `;
        }
        break;
      case this.MARKER_TYPE.trafficGraph:
        htmlString = `
            <div id="${markerId}_${this.MAP_TYPE}_${type}">
              <div class="traffic-graph"></div>
              <div class="traffic-graph-blinking hidden"></div>
            </div>
        `;
        break;
      case this.MARKER_TYPE.charging:
        htmlString = `
        <div id="${markerId}_${this.MAP_TYPE}_${type}">
        <span class="charging-label normal">${label ?? ''}</span>
        <div class="charging normal">
          <div class="charging-icon"></div>
        </div>
        <div class="charging-shadow"></div>
      </div>
        `;
    }

    return htmlString;
  }

  /**
   * Helper function to update the angle of robot facing, depend on different
   * of angle between start position and destination position
   *
   * @param robotId ID of seletced robot that want to update
   * @param robotType type of robot. it can be 'robot' or 'teleop-robot'
   * @param angle angle of robot facing
   */
  protected setRobotAngle(
    robotId: string,
    robotType: MarkerType,
    angle: number
  ): void {
    const parent = document.getElementById(
      `${robotId}_${this.MAP_TYPE}_${robotType}`
    );

    if (parent) {
      const robotFacingDom = parent.querySelector('.robot-sonar');
      robotFacingDom.setAttribute(
        'style',
        'transform-origin: bottom center 0px;transform: rotate(' +
          angle +
          'deg)'
      );
    }
  }

  /**
   * Helper function for update the marker DOM for change the color
   * if there is a new detection occur for that marker robot
   * and update it in the map
   *
   * @param markerData Data which marker to be updated
   */
  public updateMarkerHtml(markerData: MapRobotMarker): void {
    const marker = this.findMarkerFromLayoutMarker(markerData, 'robot');

    if (marker) {
      this.updateMarker(marker, markerData, 'robot');
    }
  }

  public stopRobotMovement(robots: MapRobotMarker[]): void {
    // stop the current movement if the updated marker still moving
    robots.map((robotMarker) => {
      const refId = `${robotMarker.id}_${this.MAP_TYPE}_${this.MARKER_TYPE.robot}`;
      if (this.updateRobotPositionFrame[refId]) {
        this.updateRobotPositionFrame[refId].stop();
      }
    });
  }

  public updateMarkerLatLng(lMarker: PointMarker, type: string): void {
    const refId = `${lMarker.id}_${this.MAP_TYPE}_${type}`;
    const selectedMarker = get(this._markers, refId, '');
    if (selectedMarker) {
      const coordinates: L.PointExpression = [lMarker.x, lMarker.y];
      const latlng = this.xYtoLatlng(coordinates);
      selectedMarker.setLatLng(latlng);
    }
  }
}
