import { FormControl } from '@angular/forms';

export const phoneNumberValidator = (
  formControl: FormControl
): Record<string, unknown> =>
  isValidPhoneNumber(formControl.value)
    ? null
    : {
        phoneNumberValidator: true,
      };

export const isValidPhoneNumber = (val: string): boolean =>
  /^\+?\d{5,}$/.test(val);

export const forbiddenCharsValidator = (
  formControl: FormControl
): Record<string, unknown> =>
  isForbidden(formControl.value)
    ? null
    : {
        invalid: true,
      };

export const isForbidden = (val: string): boolean =>
  /^(?!\s)(?!.*\s$)[^@#%&*()_|$:;+`~!^={}[\]\\"'<>/?]*$/.test(val);
